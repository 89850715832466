@media (max-width: 599px) {
  .hiddenMobile {
    display: none !important;
  }
}

@media (min-width: 600px) {
  .hiddenDesktop {
    display: none !important;
  }
}