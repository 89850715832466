@import 'fonts';

@import 'app/bootstrap/_bootstrap-dir';

@import 'app/global/_global-dir';
@import 'app/base/_base-dir';
@import 'app/layout/_layout-dir';
@import 'app/ui/_ui-dir';
@import 'app/pages/_pages-dir';
@import 'app/module/_module-dir';
@import 'app/edebex/_global-dir';

@import '~react-notifications/lib/notifications.css';