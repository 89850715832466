.app-logo-content {
  background-color: transparent;
  border-right: 1px solid rgba(0, 0, 0, .3);
  order: 1;
  padding: 35px 25px 20px;
  width: 40%;

  img {
    max-width: 100%;
  }
}

header {
  left: 0 !important;
}

.app-main-content {
  padding-top: 62px;
}

.app-sidebar {
  z-index: 1000;
}

.sidenav-vert-spacer {
  height: 80px;
}

// TOP NAV

.app-main-header {
  background-color: white !important;
  color: black !important;
  box-shadow: unset !important;

  .dropdown-toggle::after {
    color: #3cf;
    margin-left: .75em;
  }

  .dropdown-menu a {
    color: #2a2a2a;
    svg {
      color: #959aa1;
    }
  }
}

em {
  color: #3cf;
  font-style: normal;
}

.messages-list.user-menu ul li {
  padding: 7px 6px 6px;
}

.signout {
  color: black;
  padding: 7px;
}
