/*Sidebar Styles*/

.side-nav {
  width: $side-nav-width;
  background-color: $sidebar-bg !important;
  color: $sidebar-text-color !important;
  border-right: 0 none !important;
  z-index: 1250 !important;

  & .user-profile {
    background-color: $sidebar-bg-darken;
    padding: 13px 20px !important;
  }

  & .user-avatar {
    margin: 0 15px 0 0 !important;
    height: 44px !important;
    width: 44px !important;
    line-height: 44px;
  }

  & .user-detail {
    & .user-name {
      color: $sidebar-hover-color;
      cursor: pointer;
      font-size: $font-size-base;
      font-weight: 400;
      white-space: nowrap;
    }
  }
}

.user-detail {
  & .user-name {
    cursor: pointer;
  }
}

ul.nav-menu {
  position: relative;
  font-size: $font-size-base;
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

ul.nav-menu li {
  position: relative;
  margin-bottom: 1px;
}

ul.nav-menu li.menu {
  & + .nav-header {
    margin-top: 20px;
  }
  & .sub-menu {
    display: none;
  }
  &.open > .sub-menu {
    display: block;
  }

  &.open {
    & .menu.active > .sub-menu {
      display: block;
    }
  }
}

ul.nav-menu li a {
  color: $sidebar-text-color;
  display: block;
  font-size: 14px;
  min-width: inherit !important;
  padding: 16px 20px;
  font-weight: 400;
  text-decoration: none;
  @include border-radius(0);
}

ul.nav-menu {
  & .nav-header {
    color: $sidebar-text-color;
    padding: 10px 20px;
    font-size: 11px;
    text-transform: uppercase;

    &:first-child {
      margin-top: 10px;
    }
  }
}

ul.nav-menu > li.open > a,
ul.nav-menu > li > a:hover,
ul.nav-menu > li > a:focus {
  background-color: $sidebar-bg-darken;
  color: $sidebar-hover-color;
}

ul.nav-menu li.menu > a {
  border-left: 2px solid transparent;
}

ul.nav-menu li.menu.open > a {
  border-color: $warning;
}

ul.nav-menu li.menu > a:focus:before,
ul.nav-menu li.menu > a:hover:before {
  color: $sidebar-hover-color;
}

ul.nav-menu li i {
  margin-right: 16px;
  font-size: 15px;
}

ul.nav-menu li ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
  position: relative;
  background-color: $sidebar-bg-darken;
  overflow: hidden;
}

ul.nav-menu li ul > li > a {
  padding-left: 56px;
}

ul.nav-menu li ul > li > ul > li > a {
  padding-left: 76px;
}

ul.nav-menu li ul > li > ul > li > ul > li > a {
  padding-left: 96px;
}

ul.nav-menu li ul > li > ul > li > ul > li > ul > li > a {
  padding-left: 116px;
}

ul.nav-menu li.menu > a:before,
ul.nav-menu div.menu > a:before {
  color: $sidebar-text-color;
  content: ""; /* Right Arrow in menu \f2fb - Removed: PX-3498 */
  display: inline-block;
  float: right;
  font: {
    family: "Material-Design-Iconic-Font";
    size: 15px;
  }
  margin-left: 10px;
  position: relative;
  @include transition(all 300ms ease-in-out);
  font-weight: normal;
}

/*to make the menu in the ISO profile*/

ul.nav-menu div.menu.up > div:before {
  color: $sidebar-text-color;
  content: "\f2fc";
  display: inline-block;
  float: right;
  font: {
    family: "Material-Design-Iconic-Font";
    size: 15px;
  }
  margin-left: 10px;
  position: relative;
  @include transition(all 300ms ease-in-out);
  font-weight: normal;
}

ul.nav-menu div.menu.down > div:before {
  color: $sidebar-text-color;
  content: "\f2f9";
  display: inline-block;
  float: right;
  font: {
    family: "Material-Design-Iconic-Font";
    size: 15px;
  }
  margin-left: 10px;
  position: relative;
  @include transition(all 300ms ease-in-out);
  font-weight: normal;
}

ul.nav-menu div.menu.no-arrow > div:before {
  display: none;
}

.app-container.mini-drawer
  .side-nav:hover
  ul.nav-menu
  > div
  > div
  > div.menu
  > div:before {
  display: block !important;
}

ul.nav-menu div.menu.no-arrow > a:before {
  display: none;
}

.app-container.mini-drawer
  .side-nav:hover
  ul.nav-menu
  > div
  > div.menu
  > a:before {
  display: block !important;
}

svg.svg-inline--fa.fa-angle-right.fa-w-8 {
  color: #3cf;
}

/**/

ul.nav-menu li.menu.no-arrow > a:before {
  display: none;
}

ul.nav-menu li.menu.open > a:before,
ul.nav-menu li.menu > a:focus:before,
ul.nav-menu li.menu li > a:hover:before {
  background-color: transparent;
  color: $sidebar-hover-color;
}

ul.nav-menu li.menu.open > a:before,
ul.nav-menu li.menu.active > a:before {
  @include rotate(90deg);
}

ul.nav-menu li.menu.open .sub-menu li > a.active {
  background-color: $sidebar-bg-darken;
  color: $sidebar-hover-color;
}

ul.nav-menu li.menu .sub-menu li > a:hover,
ul.nav-menu li.menu .sub-menu li > a:focus,
ul.nav-menu li.menu.open .sub-menu li > a.active:before,
ul.nav-menu li.menu .sub-menu li > a:focus:before,
ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: $sidebar-bg-darken;
  color: $sidebar-hover-color;
}

.app-container.mini-drawer {
  & .side-nav {
    @include transition($transition-base);
    width: $mini-drawer-width;

    & .nav-menu li span.nav-text,
    & .user-profile .user-detail,
    & .customizer {
      display: none;
    }

    & .nav-menu .menu.open > .sub-menu {
      display: none !important;
    }

    & .nav-header {
      padding: 10px;
    }

    & .nav-menu li {
      text-align: center;
      i {
        margin-right: 0;
      }
    }

    & ul.nav-menu {
      & li a {
        padding: 16px 20px;
      }
      & ul.sub-menu li a {
        padding-left: 30px;
      }
    }

    & ul.nav-menu > li > a {
      &:before {
        display: none;
      }
    }
  }
}

.app-container.mini-drawer {
  & .side-nav:hover {
    width: $side-nav-width;

    & .nav-menu li span.nav-text,
    & .user-profile .user-detail,
    & .nav-menu span.nav-text {
      display: inline-block !important;
    }

    & .nav-menu .menu.open > .sub-menu {
      display: block !important;
    }

    & .nav-header {
      padding: 10px 20px;
    }

    & .customizer {
      display: flex !important;
    }

    & ul.nav-menu {
      & li {
        text-align: left;
        & i {
          margin-right: 16px;
        }

        & a {
          padding: 16px 20px;
        }
      }

      & ul.sub-menu li a {
        padding-left: 56px;
      }

      ul.sub-menu > li > ul > li > a {
        padding-left: 76px;
      }

      ul.sub-menu > li > ul > li > ul > li > a {
        padding-left: 96px;
      }

      ul.sub-menu > li > ul > li > ul > li > ul > li > a {
        padding-left: 116px;
      }
    }

    & ul.nav-menu > li > a {
      &:before {
        display: block;
      }
    }
  }
}

.menu-iso {
  display: none;
}

.menu-debtor {
  display: none;
}

.menu-confirming {
  display: none;
}

.customizer {
  padding: 20px 35px;
  & img {
    padding: 5px;
    cursor: pointer;
  }
}

.user-info {
  & ul {
    & li {
      font-size: 15px;
    }
  }
}
